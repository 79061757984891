import styled from 'styled-components';

const SectionWrapper = styled.section`
  padding: 100px 0 0 0;
  background: linear-gradient(
    35deg,
    rgb(51, 196, 129) 0%,
    rgb(24, 92, 55) 100%
  );
  @media (max-width: 990px) {
    padding: 80px 0 0 0;
  }
  @media (max-width: 575px) {
    padding: 60px 0 0 0;
  }

  @keyframes ScaleInUp {
    from {
      opacity: 0;
      transform: translateY(30px) scale(0.97);
    }
    to {
      opacity: 1;
      transform: translateY(0) scale(1);
    }
  }
`;

export default SectionWrapper;
