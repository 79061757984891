import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import Box from 'reusecore/src/elements/Box';
import Text from 'reusecore/src/elements/Text';
import Heading from 'reusecore/src/elements/Heading';
import {
  Accordion,
  AccordionItem,
  AccordionTitle,
  AccordionBody,
  IconWrapper,
  OpenIcon,
  CloseIcon,
} from 'common/src/components/Accordion';
import Container from 'common/src/components/UI/Container';
import { Icon } from 'react-icons-kit';
import { plus } from 'react-icons-kit/entypo/plus';
import { minus } from 'react-icons-kit/entypo/minus';

import FaqWrapper from './faq.style';

const FAQ_DATA = [
  {
    title: 'Are any discounts available?',
    description: (
      <div className="faq">
        We offer the following discounts. Please{' '}
        <Link to="/#contact_us_section">contact us</Link> to request a discount.
        <ul>
          <li>
            50% discount for churches and non-profit 501(c)3 charitable
            organizations
          </li>
          <li>
            50% discount if you previously purchased PrintableCal version 1.x
          </li>
          <li>25% discount for students and educators</li>
        </ul>
      </div>
    ),
  },
  {
    title: 'How do the different versions compare?',
    description: (
      <div className="faq">
        <ul>
          <li>
            The <strong>Lite</strong> version is free and can be used to
            generate calendars using any of the pre-installed templates. The
            calendars won't contain any data, but that can be manually entered.
            The fully-functional trial version will be converted to the Lite
            version after 30 days if not purchased. The Lite version might be
            free, but it still provides a lot of functionality that you'd have
            to pay for elsewhere, including over 90 calendar templates and the
            ability to insert mini calendars, dates, and calendar legends.
          </li>
          <li>
            The <strong>Basic</strong> version includes all the features of the Lite version, plus the ability to include
            events or tasks associated with a single calendar data source in the output. Calendar data can be imported from Google Calendar, Outlook, Microsoft 365, iCalendar files/URLs, Todoist, Vueminder, CSV files, and many other sources.
          </li>
          <li>
            The <strong>Standard</strong> version includes all the features of the Basic version, but can include
            data from up to 3 calendar data sources in the generated output. It also
            provides the ability to customize templates or import custom
            templates that other people have created.
          </li>
          <li>
            The <strong>Premium</strong> version includes all the features of the Standard version, but can include data from up to 6 calendar data sources in the generated output.
          </li>
          <li>
            The <strong>Ultimate</strong> version is for those people that want
            the very best. This version has every feature enabled. It can overlay
            data from an unlimited number of calendars in the generated output. Customers that purchase the Ultimate version also receive our top priority when requesting support.
          </li>
        </ul>
      </div>
    ),
  },
  {
    title: 'Can I try the software before deciding to purchase?',
    description: (
      <div className="faq">
        <p>
          Absolutely. When you download PrintableCal, all the features of the
          Ultimate version will be available for 30 days. After 30 days,
          PrintableCal will be switched to the free Lite version, which you can
          use for as long as you'd like. Some features, such as the ability to
          customize templates, won't be available in the Lite version. Other
          features, like the ability to import data from an unlimited number of
          data sources, will be still be provided but in a demo mode.
        </p>
        <p>
          All the templates that are installed with paid versions or created
          while using the 30-day trial will continue to be available for use in
          the Lite version. There are no watermarks in the output or anything
          else that could detract the appearance of the generated calendars. We
          feel the Lite version provides a great introduction to the software
          and is very useful even without the additional features provided by
          the paid versions.
        </p>
      </div>
    ),
  },
  {
    title: 'How many licenses will I need?',
    description: (
      <div className="faq">
        PrintableCal is licensed to individual users. A single user license can
        be activated up to 3 computers, but only by the same user. Additional
        licenses will be needed for multiple users or for activating on more
        than 3 computers by the same user. If you get a new computer and you've
        previously activated on the maximum of 3 computers, PrintableCal will
        provide you with the option to transfer your license to your new
        computer as part of the activation process.
      </div>
    ),
  },
  {
    title: 'Which payment methods are accepted?',
    description: (
      <div className="faq">
        We accept the following methods of payment when ordering online:
        <ul>
          <li>Credit card (Visa, MasterCard, American Express, Discover)</li>
          <li>PayPal</li>
        </ul>
        <p>
          We can also accept other payment methods, such as purchase orders or
          wire transfers. Please contact{' '}
          <Link to="/#contact_us_section">contact us</Link> if you require a
          different payment method.
        </p>
      </div>
    ),
  },
  {
    title: 'Can I upgrade after purchasing, such as from Standard to Premium?',
    description: (
      <div className="faq">
        If you purchase the Basic, Standard, or Premium version and then later decide
        you'd like to upgrade in order to unlock additional features, you can
        purchase the upgrade from within PrintableCal. The price will be
        prorated based on what you've already paid.
      </div>
    ),
  },
  {
    title:
      'How long after purchasing will I be able to receive product updates?',
    description: (
      <div className="faq">
        You can receive updates for as long as your subscription is active. That
        includes any major new releases, in addition to minor updates and bug
        corrections.
      </div>
    ),
  },
  {
    title: 'Is there a money-back guarantee?',
    description: (
      <div className="faq">
        Yes, we offer a 30-day money-back guarantee for new customers (not for
        upgrades). You can request a refund by{' '}
        <Link to="/#contact_us_section">contacting us</Link>. Please include
        information about why you're seeking a refund, in case there's something
        we could do that would keep you as a happy customer.
      </div>
    ),
  },
  {
    title: 'How long can I receive support?',
    description: (
      <div className="faq">
        Your purchase includes free and unlimited customer support for as long
        as your subscription is active. All support inquiries will be answered
        within 1 business day, and usually much sooner. If you purchase the
        Ultimate version, your support request will receive top priority.
      </div>
    ),
  },
  {
    title: 'How long will it take to receive my license key after purchasing?',
    description: (
      <div className="faq">
        A license key will be emailed within a couple minutes of submitting your
        order. If you haven't received the email within a few minutes, check
        your spam folder. If you don't see the email in your spam filter, please{' '}
        <Link to="/#contact_us_section">contact us</Link> for assistance. After
        you receive your license key, you can activate your license by clicking
        the Activate button on the PrintableCal tab of the ribbon menu in Excel,
        Word, or PowerPoint.
      </div>
    ),
  },
];

const FaqSection = ({
  sectionWrapper,
  row,
  col,
  secTitleWrapper,
  secHeading,
  secText,
  title,
  description,
}) => {
  return (
    <Box {...sectionWrapper} id="faq_section">
      <Container>
        <Box {...secTitleWrapper}>
          <Text {...secText} content="FREQUENTLY ASK QUESTIONS" />
          <Heading
            {...secHeading}
            content="Answers to questions about discounts, licensing, upgrades, and more."
          />
        </Box>
        <Box {...row}>
          <Box {...col}>
            <FaqWrapper>
              <Accordion>
                <>
                  {FAQ_DATA.map((accordionItem, index) => (
                    <AccordionItem
                      className="accordion_item"
                      uuid={`accordion-${index}`}
                      key={`accordion-${index}`}
                      expanded={accordionItem.expand}
                    >
                      <>
                        <AccordionTitle className="accordion_title">
                          <>
                            <Heading {...title} content={accordionItem.title} />
                            <IconWrapper>
                              <OpenIcon>
                                <Icon icon={minus} size={18} />
                              </OpenIcon>
                              <CloseIcon>
                                <Icon icon={plus} size={18} />
                              </CloseIcon>
                            </IconWrapper>
                          </>
                        </AccordionTitle>
                        <AccordionBody className="accordion_body">
                          <Text
                            {...description}
                            as="div"
                            content={accordionItem.description}
                          />
                        </AccordionBody>
                      </>
                    </AccordionItem>
                  ))}
                </>
              </Accordion>
            </FaqWrapper>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

FaqSection.propTypes = {
  sectionWrapper: PropTypes.object,
  secTitleWrapper: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  secHeading: PropTypes.object,
  secText: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
  buttonWrapper: PropTypes.object,
  button: PropTypes.object,
};

FaqSection.defaultProps = {
  sectionWrapper: {
    as: 'section',
    pt: ['20px', '30px', '50px', '50px'],
    pb: ['60px', '80px', '80px', '80px'],
  },
  secTitleWrapper: {
    mb: ['55px', '65px'],
  },
  secText: {
    as: 'span',
    display: 'block',
    textAlign: 'center',
    fontSize: '14px',
    letterSpacing: '0.15em',
    fontWeight: '700',
    color: '#007C3E',
    mb: '5px',
  },
  secHeading: {
    fontSize: ['32px', '32px', '40px', '42px', '46px'],
    fontFamily: 'Google Sans',
    fontWeight: '400',
    letterSpacing: '-0.5px',
    lineHeight: '1.17857',
    textAlign: 'center',
    color: '#0f2137',
    mb: '0',
  },
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  col: {
    width: ['100%', '100%', '75%', '75%'],
  },
  title: {
    fontSize: ['22px', '24px', '28px', '28px', '28px'],
    fontWeight: '400',
    letterSpacing: '-0.5px',
    lineHeight: '1.17857',
    mb: 0,
  },
  description: {
    fontSize: '15px',
    color: 'textColor',
    lineHeight: '1.5',
    mb: 0,
  },
};

export default FaqSection;
