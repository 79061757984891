import styled from 'styled-components';

const FaqWrapper = styled.div`
  .accordion_item {
    margin-bottom: 5px;
  }

  .accordion_title {
  }

  .accordion_body {
    padding: 0 30px 23px 30px;
    @media (max-width: 575px) {
      padding: 0 20px 15px 20px;
    }
  }

  .faq li {
    list-style-type: circle;
    margin-left: 20px;
  }

  .faq a:hover {
    text-decoration: underline;
  }
`;

export default FaqWrapper;
