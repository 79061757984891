import React from 'react';
import PropTypes from 'prop-types';
import 'rc-tabs/assets/index.css';
import Box from 'reusecore/src/elements/Box';
import Text from 'reusecore/src/elements/Text';
import Heading from 'reusecore/src/elements/Heading';
import Container from 'common/src/components/UI/Container';
import TiltShape from '../TiltShape';
import 'swiper/css/swiper.css';
import SectionWrapper from './updateScreen.style';
import TemplateSlider from '../TemplateSlider';

const UpdateScreen = ({ secTitleWrapper, secText, secHeading }) => {
  return (
    <SectionWrapper id="templates_section">
      <TiltShape />
      <Container>
        <Box {...secTitleWrapper}>
          <Text {...secText} content="TEMPLATES" />
          <Heading
            {...secHeading}
            content="Customize and print calendars including holidays and schedules defined in other calendar apps"
          />
          <Text
            style={{ color: 'white', marginTop: 32, lineHeight: '24px' }}
            content="Over 90 calendar templates are included with PrintableCal. Each template can automatically include holidays, events, and tasks from Google Calendar, Outlook, iCloud, iCalendar URLs, and many other sources. The screenshots below show a few examples of the output that can be generated with these templates."
          />
        </Box>
        <TemplateSlider />
      </Container>
    </SectionWrapper>
  );
};

UpdateScreen.propTypes = {
  secTitleWrapper: PropTypes.object,
  secText: PropTypes.object,
  secHeading: PropTypes.object,
};

UpdateScreen.defaultProps = {
  secTitleWrapper: {
    mb: 32,
  },
  secText: {
    as: 'span',
    display: 'block',
    textAlign: 'center',
    fontSize: '14px',
    letterSpacing: '0.15em',
    fontWeight: '700',
    color: '#fff',
    mb: '5px',
  },
  secHeading: {
    fontSize: ['32px', '32px', '40px', '42px', '46px'],
    fontFamily: 'Google Sans',
    fontWeight: '400',
    letterSpacing: '-0.5px',
    lineHeight: '1.17857',
    textAlign: 'center',
    color: '#fff',
    mb: '0',
  },
};

export default UpdateScreen;
