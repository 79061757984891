import React from 'react';
import PropTypes from 'prop-types';
import Box from 'reusecore/src/elements/Box';
import Text from 'reusecore/src/elements/Text';
import Heading from 'reusecore/src/elements/Heading';
import Container from 'common/src/components/UI/Container';
import ContactForm from 'common/src/components/ContactForm';

const ContactUsSection = ({
  sectionWrapper,
  secTitleWrapper,
  secText,
  secHeading,
}) => {
  return (
    <Box {...sectionWrapper} as="section" id="contact_us_section">
      <Container>
        <Box {...secTitleWrapper}>
          <Text {...secText} content="CONTACT US" />
          <Heading
            {...secHeading}
            content="Have questions about PrintableCal? We're here to help."
          />
          <p style={{marginBottom: 40}}>Contact us by filling out the form below or by sending an email to <a href="mailto:support@printablecal.com">support@printablecal.com</a>. We'll respond within 1 business day, and faster if you own a PrintableCal Ultimate license.</p>
          <ContactForm />
        </Box>
      </Container>
    </Box>
  );
};

ContactUsSection.propTypes = {
  sectionHeader: PropTypes.object,
};

ContactUsSection.defaultProps = {
  sectionWrapper: {
    pt: 100,
    pb: 100,
  },
  secTitleWrapper: {
    pt: 0,
    mb: 0,
  },
  secText: {
    as: 'span',
    display: 'block',
    textAlign: 'center',
    fontSize: '14px',
    letterSpacing: '0.15em',
    fontWeight: '700',
    color: '#007C3E',
    mb: '5px',
  },
  secHeading: {
    fontSize: ['32px', '32px', '40px', '42px', '46px'],
    fontFamily: 'Google Sans',
    fontWeight: '400',
    letterSpacing: '-0.5px',
    lineHeight: '1.17857',
    textAlign: 'center',
    color: '#0f2137',
    mb: '48px',
  },
};

export default ContactUsSection;
