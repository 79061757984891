import React, { Fragment } from 'react';
import Sticky from 'react-stickynode';
import { DrawerProvider } from 'common/src/contexts/DrawerContext';
import { ResetCSS } from 'common/src/assets/css/style';
import {
  GlobalStyle,
  ContentWrapper,
} from '../containers/SaasModern/sassModern.style';

import BannerSection from '../containers/SaasModern/Banner';
import Navbar from '../containers/SaasModern/Navbar';
import PricingSection from '../containers/SaasModern/Pricing';
import FaqSection from '../containers/SaasModern/Faq';
import FeatureSection from '../containers/SaasModern/Feature';
import UpdateScreen from '../containers/SaasModern/UpdateScreen';
import Footer from '../containers/SaasModern/Footer';
import SEO from '../components/seo';
import ContactUsSection from '../containers/SaasModern/ContactUs';
import Layout from '../components/layout';
import {connect} from "react-redux";

const mapStateToProps = ({ paddleAffiliateId }) => {
  return { paddleAffiliateId };
};

class HomePage extends React.Component {
  render() {
    const { paddleAffiliateId } = this.props;

    return (
      <Layout>
        <Fragment>
          <SEO
          title="Create Printable Calendars with 90+ Calendar Templates for Excel, Word, and PowerPoint"
          description="Create printable calendars in Excel, Word, and PowerPoint. Over 90 free calendar templates are included. Automatically generate calendars containing holidays, events, and tasks from Google Calendar, Outlook, iCalendar URLs, and more."
          keywords="printable calendar, print calendar, print Google Calendar, print Outlook calendar, create printable calendar, create calendar, calendar template, free calendar template, printable calendar template, Excel calendar template, Word calendar template, 2020 calendar template"
          />

          <ResetCSS/>
          <GlobalStyle/>

          <ContentWrapper>
            <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
              <DrawerProvider>
                <Navbar/>
              </DrawerProvider>
            </Sticky>
            <BannerSection/>
            <FeatureSection/>
            <UpdateScreen/>
            <PricingSection paddleAffiliateId={paddleAffiliateId}/>
            <FaqSection/>
            <ContactUsSection/>
            <Footer/>
          </ContentWrapper>
        </Fragment>
      </Layout>
    );
  };
}

export default connect(mapStateToProps)(HomePage);