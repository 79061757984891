import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Box from 'reusecore/src/elements/Box';
import Text from 'reusecore/src/elements/Text';
import Heading from 'reusecore/src/elements/Heading';
import Container from 'common/src/components/UI/Container';
import TiltShape from '../TiltShape';
import { BannerWrapper } from './banner.style';
import LargeDownloadButton from 'common/src/components/LargeDownloadButton';
import Image from 'gatsby-image';
import Fade from 'react-reveal/Fade';
import { Link } from 'gatsby';

const BannerSection = ({
  row,
  contentWrapper,
  title,
  description,
  imageWrapper,
}) => {
  const Data = useStaticQuery(graphql`
    query {
      bannerImage: file(relativePath: { eq: "banner-image.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1170) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `);

  const versionContent = (
      <>
        Version 2023.01 - August 2, 2023 - <Link to="/Whats-New" style={{color: 'white', textDecoration: 'underline'}}>What's New</Link>
      </>
  );

  return (
    <BannerWrapper id="banner_section">
      <TiltShape />
      <Container>
        <Box {...row}>
          <Box {...contentWrapper}>
            <Heading
              {...title}
              as={'h1'}
              style={{ fontFamily: ['Google Sans', 'sans-serif'], marginLeft: '15%', marginRight: '15%' }}
              content="Create printable calendars in Excel, Word, and PowerPoint"
            />
            <Text
              {...description}
              style={{ fontFamily: ['Roboto', 'sans-serif'] }}
              content="PrintableCal includes over 90 free calendar templates which can automatically populate with holidays, events, and tasks from Outlook, Google Calendar, iCloud, Yahoo! Calendar, Exchange Server, Todoist, iCalendar files, CSV files, and more. "
            />
            <LargeDownloadButton showRequirements={false}/>
            <div style={{marginTop: 12}}>
              <Text
                  {...description}
                  style={{ fontFamily: ['Roboto', 'sans-serif'], fontSize: 14 }}
                  content={versionContent}
              />
              <Text
                  {...description}
                  style={{ fontFamily: ['Roboto', 'sans-serif'], fontSize: 14 }}
                  content="Requires Microsoft Office 365 / 2007 or later for Windows. Fully compatible with 64-bit versions of Office, including Office 2021."
              />
            </div>
          </Box>
          <Box {...imageWrapper}>
            <Fade bottom>
              <Image
                fluid={Data.bannerImage.childImageSharp.fluid}
                alt="Create printable calendars in Excel and Word."
              />
            </Fade>
          </Box>
        </Box>
      </Container>
    </BannerWrapper>
  );
};

BannerSection.propTypes = {
  row: PropTypes.object,
  contentWrapper: PropTypes.object,
  discountAmount: PropTypes.object,
  discountText: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
  imageWrapper: PropTypes.object,
};

BannerSection.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
  },
  contentWrapper: {
    mb: '40px',
  },
  imageWrapper: {
    width: '100%',
  },
  title: {
    color: '#fff',
    fontSize: ['34px', '42px', '50px', '52px', '56px'],
    fontWeight: '400',
    letterSpacing: '-0.5px',
    lineHeight: '1.17857',
    mb: ['20px', '25px', '25px', '25px', '25px'],
    textAlign: 'center',
  },
  description: {
    fontSize: ['15px', '16px', '18px', '18px', '18px'],
    fontWeight: '500',
    color: '#fff',
    lineHeight: '1.6',
    mb: '0',
    textAlign: 'center',
  },
  discountAmount: {
    fontSize: ['13px', '14px', '14px', '14px', '14px'],
    fontWeight: '700',
    color: '#00865b',
    mb: 0,
    as: 'span',
    mr: '0.4em',
  },
  discountText: {
    fontSize: ['13px', '14px', '14px', '14px', '14px'],
    fontWeight: '700',
    color: '#fff',
    mb: 0,
    as: 'span',
  },
};

export default BannerSection;
